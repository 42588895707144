import React from "react";
import icon from "../images/GitHub.png"

function GitHub(){
    return(
        <div className="rounded-md w-auto">
           <img className="w-auto" src={icon}/>
        </div>
    )
}

export default GitHub